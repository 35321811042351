import config from '../config/config'
import { axios } from './index'

let baseUrl = config.baseUrl + '/knowledge/api/v1/ability'

export default {
  //获取能力下的知识点
  getKnowledgeSimple(payload) {
    return axios.get(`${baseUrl}/get/knowledge/simple?abilityId=${payload}`)
  },
  //获取企业下能力和能力对应的题目数量
  getAbilityQuestionCount() {
    return axios.get(`${baseUrl}/get/ability/question/count`)
  },
  //获取试卷下能力对应的题目数量
  getPaperQuestionCount(data) {
    return axios.post(`${baseUrl}/get/paper/ability/question/count`, data)
  },
  //获取企业技能列表 查全部的时候传null
  getOrgAbility(payload) {
    return axios.get(`${baseUrl}/get/org/ability?postId=${payload}`)
  },
  //添加技能
  addOrgAbility(payload) {
    return axios.post(`${baseUrl}/add`, payload)
  },
  //修改技能
  modifyOrgAbility(payload) {
    return axios.post(`${baseUrl}/edit/name`, payload)
  },
  //删除技能
  delOrgAbility(id) {
    return axios.get(`${baseUrl}/delete?abilityId=${id}`)
  },
  //获取技能详情
  getOrgAbilityInfo(id) {
    return axios.get(`${baseUrl}/get/ability?abilityId=${id}`)
  },
  //删除关联能力的知识点
  delOrgAbilityknowledge(payload) {
    return axios.post(`${baseUrl}/delete/knowledge`, payload)
  },
  //添加技能关联知识点
  addKnowledge(payload) {
    return axios.post(`${baseUrl}/relation/knowledge`, payload)
  },
  //修改技能描述
  editDescribe(payload) {
    return axios.post(`${baseUrl}/edit/describe`, payload)
  },
  //获取技能关联的树
  getAllAbilityData() {
    return axios.get(baseUrl + '//getAll')
  },
  //获取企业岗位能力下技能列表
  getAbilityListByOrganization(payload) {
    return axios.get(`${baseUrl}/get/org/skill/ability?mainSkillId=${payload}`)
  }
}
