import config from '../config/config'
import { axios } from './index'

let chapterUrl = config.baseUrl + '/course/api/v1/chapter'
export default {
  //获取课程下章节简略信息
  getChapterSimpleByCourseId(payload) {
    return axios.get(`${chapterUrl}/course/chapter/simple?courseId=${payload}`)
  }
}
